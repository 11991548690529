@import 'tailwindcss/base';

@import 'tailwindcss/components';
@import './components.css';
/* overwrite Sendbird styles */
@import './chat.css';
/* overwrite React Datepicker styles */
@import './datepicker.css';

@import 'tailwindcss/utilities';

:root {
  --body-height: calc(100vh - env(safe-area-inset-bottom, 1rem));
}

body {
  @apply font-normal antialiased max-w-[100vw];
  min-height: var(--body-height);
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

@layer components {
  .two-col {
    column-count: 2;
    column-gap: 40px;
  }

  .tabbed-navigation {
    @apply overflow-x-auto;
    width: calc(100vw - 32px);
  }

  /* nprogress bar color overrides */
  #nprogress .bar {
    @apply bg-leland-primary h-1 !important;
  }

  #nprogress .peg {
    @apply shadow-md shadow-leland-primary !important;
  }

  #nprogress .spinner-icon {
    @apply border-leland-primary !important;
  }
}
