.react-datepicker {
  @apply border border-leland-gray-stroke rounded-lg shadow-lg !important;
  font-family: 'Calibre';
}

.react-datepicker-wrapper {
  @apply w-full;
}

.react-datepicker .react-datepicker__header {
  @apply bg-white border-none rounded-lg;
}

.react-datepicker .react-datepicker__current-month {
  @apply text-sm my-2.5;
}

.react-datepicker .react-datepicker__navigation {
  @apply border border-leland-gray-stroke rounded-lg m-2.5;
}

.react-datepicker .react-datepicker__navigation-icon--previous {
  @apply top-[5px] right-0.5;
}

.react-datepicker .react-datepicker__navigation-icon--next {
  @apply top-[5px] left-0.5;
}

.react-datepicker .react-datepicker__day--outside-month {
  @apply text-leland-gray-light;
}

.react-datepicker .react-datepicker__year-read-view--down-arrow,
.react-datepicker .react-datepicker__month-read-view--down-arrow,
.react-datepicker.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker .react-datepicker__navigation-icon::before {
  @apply border-leland-gray-dark;
}

.react-datepicker .react-datepicker__current-month {
  @apply text-leland-gray-dark;
}

.react-datepicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker .react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--in-range,
.react-datepicker .react-datepicker__day--keyboard-selected,
.react-datepicker .react-datepicker__day--keyboard-selected:hover,
.react-datepicker .react-datepicker__day--selected:hover {
  @apply bg-leland-gray-dark text-white;
}

.react-datepicker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  @apply bg-leland-gray-dark;
}

.react-datepicker .react-datepicker__day--today:hover:not(--in-range),
.react-datepicker
  .react-datepicker__day--in-selecting-range:not(--selecting-range-start) {
  @apply bg-leland-gray-hover text-black;
}

.react-datepicker .react-datepicker__day--in-range:hover {
  @apply bg-black;
}

.react-datepicker .react-datepicker__day,
.react-datepicker .react-datepicker__day:hover {
  @apply rounded-lg;
}

.react-datepicker .react-datepicker__day-name,
.react-datepicker .react-datepicker__day,
.react-datepicker .react-datepicker__time-name {
  @apply w-8 leading-8;
}

.react-datepicker .react-datepicker__month {
  @apply mt-0;
}

.react-datepicker .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  @apply rounded-sm;
}

.react-datepicker-popper[data-placement^='top'] {
  @apply pb-0 !important;
}

.react-datepicker-popper[data-placement^='bottom'] {
  @apply pt-0 !important;
}

.react-datepicker__tab-loop {
  @apply absolute top-0;
}
