/* TODO: Investigate moving these to tailwind configuration */

@layer components {
  .button--disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .input {
    @apply p-4 rounded-lg border border-leland-gray-stroke box-border placeholder-gray-400;
  }

  .input:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .link {
    @apply hover:underline;
    text-underline-offset: 2px;
  }

  .link--primary {
    @apply text-leland-primary;
  }

  .link--blue {
    @apply text-leland-blue;
  }

  .link--destructive {
    @apply text-leland-red;
  }

  .form-radio {
    @apply border-leland-gray-stroke rounded-full checked:border-[#169E6D] !important;
  }

  .form-textarea:focus,
  .form-select:focus,
  .form-multiselect:focus,
  .form-radio:focus {
    @apply border-leland-gray-stroke outline-0 outline-none shadow-none ring-0 !important;
  }

  .form-input:focus {
    @apply transition duration-100 border-leland-gray-extra-light outline-0 outline-none shadow-none ring-0 !important;
  }
}
